



















































































































































































































































































































































































import Vue from 'vue'
import { Component, Watch } from 'vue-property-decorator'

/* eslint-disable camelcase */
// TODO fix file

class CustomerDefaultData {
  name: string
  type: number
  group_id: number
  chain_customer_identifier: string
  vat_number: string
  active: boolean
  fortnox_way_of_delivery: string
}

class CustomerAddressData {
  address_1: string
  address_2: string
  zipcode: string
  city: string
  country: string
  fiscal_country: string
  map_link: string
}

class CustomerContactData {
  email: string
  invoice_email: string
  phone: string
  mobile: string
  fax: string
  marketing_not_ok_email: boolean
  marketing_not_ok_sms: boolean
}

class CustomerPaymentData {
  invoice_allowed: boolean
  invoice_due_days: number
  credit_limit: number
  invoice_emailed: boolean
  use_invoice_reference: boolean
  default_invoice_reference: string
  no_vat: boolean
  default_payment_type: string
  fortnox_cost_center: string
}

class CustomerNotificationData {
  type: string
}

class CustomerPriceListData {
  price_list_id: number
}

class CustomerNotificationSettingsData {
  notification_settings: string
}

@Component
export default class Customer extends Vue {
  editCustomerTabs = null as string

  loading = false

  url = null
  defaultData: CustomerDefaultData = new CustomerDefaultData()
  addressData: CustomerAddressData = new CustomerAddressData()
  contactData: CustomerContactData = new CustomerContactData()
  paymentData: CustomerPaymentData = new CustomerPaymentData()
  notificationData: CustomerNotificationData = new CustomerNotificationData()
  priceListData: CustomerPriceListData = new CustomerPriceListData()
  notificationSettingsData: CustomerNotificationSettingsData = new CustomerNotificationSettingsData()

  typeList = []
  groupList = []
  countryList = []
  consentList = []
  selectedConsents = {}
  defaultPaymentTypeList = []
  notificationSettingsList = []

  customerModelSearch = ''
  customerModelSearchLoading = false
  customerModelSearchItems = []

  tabFields = {
    default: [
      'name',
      'type',
      'group_id',
      'chain_customer_identifier',
      'vat_number',
      'active',
      'fortnox_way_of_delivery',
    ],
    address: ['address_1', 'address_2', 'zipcode', 'city', 'country', 'fiscal_country', 'map_link'],
    contact: ['email', 'invoice_email', 'phone', 'mobile', 'fax', 'marketing_not_ok_email', 'marketing_not_ok_sms'],
    payment: [
      'invoice_allowed',
      'invoice_due_days',
      'credit_limit',
      'invoice_emailed',
      'use_invoice_reference',
      'default_invoice_reference',
      'no_vat',
      'default_payment_type',
      'fortnox_cost_center',
    ],
    priceList: ['price_list_id'],
    notificationSettings: ['notification_settings'],
  }

  mounted() {
    // TODO: get the list via API call
    this.typeList = [
      { label: 'None' },
      { label: 'Company customer', value: 1 },
      { label: 'Private customer', value: 2 },
      { label: 'Subsidiary', value: 3 },
    ]

    // TODO: get the list via API call
    this.defaultPaymentTypeList = [
      { label: 'Cash', value: 'cash' },
      { label: 'Card', value: 'card' },
      { label: 'Invoice', value: 'invoice' },
      { label: 'Merge-invoice', value: 'merge-invoice' },
      { label: 'Chain-invoice', value: 'chain-invoice' },
    ]

    this.$axios
      .get('/v3/customer/groups')
      .then((response) => {
        const data = response.data.data

        this.groupList = data
        this.groupList.unshift({
          name: 'None',
          _id: null,
        })
      })
      .catch((error) => {
        console.error(error)
      })

    this.$axios
      .get('/v3/country')
      .then((response) => {
        const data = response.data.data

        this.countryList = data
      })
      .catch((error) => {
        console.error(error)
      })

    this.$axios
      .get('/v3/notification/settings')
      .then((response) => {
        const data = response.data.data

        this.notificationSettingsList = data
      })
      .catch((error) => {
        console.error(error)
      })

    this.$axios
      .get(`/v3/consent/preferences/customer/${this.$route.params.id}`)
      .then((response) => {
        const data = response.data.data

        if (data) {
          data.forEach((e) => {
            this.selectedConsents[e.consent_id] = e.value === 1
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })

    this.$axios
      .get('/v3/consent')
      .then((response) => {
        const data = response.data.data

        this.consentList = data
      })
      .catch((error) => {
        console.error(error)
      })

    this.$axios
      .get('/v3/customer/' + this.$route.params.id)
      .then((response) => {
        const data = response.data.data

        for (const prop in this.tabFields) {
          this.tabFields[prop].forEach((fieldName) => {
            const dataFieldName = prop + 'Data'
            this[dataFieldName][fieldName] = data[fieldName]
          })
        }
      })
      .catch((error) => {
        console.error(error)
      })
  }

  selectCustomer(val) {
    // console.log(val)
  }

  @Watch('customerModelSearch')
  onCustomerModelSearchChanged(val) {
    this.customerModelSearchLoading = true
    this.$axios
      .get(`/v3/customer/search/${val}`)
      .then((response) => {
        this.customerModelSearchItems = response.data.data.map((i) => i.name)
        this.customerModelSearchLoading = false
      })
      .catch((error) => {
        this.customerModelSearchLoading = false
      })
  }

  save(type) {
    this.loading = true

    if (type === 'unAuthenticated.vue') {
      this.defaultData.active = this.defaultData.active !== false
    }

    if (type === 'contact') {
      this.contactData.marketing_not_ok_email = this.contactData.marketing_not_ok_email === true
      this.contactData.marketing_not_ok_sms = this.contactData.marketing_not_ok_sms === true
    }

    this.$axios
      .put('/v3/customer/update/' + type + '/' + this.$route.params.id, this[type + 'Data'])
      .then((response) => response.data)
      .then((data) => {
        this.loading = false
      })
  }

  saveConsentPreferences() {
    this.loading = true

    // make values
    const consentValues = []
    for (const prop in this.selectedConsents) {
      consentValues.push({
        consent_id: parseInt(prop),
        value: this.selectedConsents[prop],
      })
    }

    const preferenceBody = {
      subject_type: 0, // 0 = CUSTOMER
      subject_id: this.$route.params.id,
      values: consentValues,
    }

    this.$axios
      .post('/v3/consent/preferences', preferenceBody)
      .then((response) => response.data)
      .then((data) => {
        this.loading = false
      })
  }
}
